// import { TypographyOptions } from '@mui/material/styles/createTypography';
// import { CustomTypography } from 'theme/types';
import { ThemeOption } from './types';

//import project

export default function themeTypography(theme: ThemeOption) {
    return {
        h6: {
            fontWeight: 500,
            color: theme.heading!,
            fontSize: '0.75rem'
        },
        h5: {
            fontSize: '0.875rem',
            color: theme.heading!,
            fontWeight: 500
        },
        h4: {
            fontSize: '1rem',
            color: theme.heading!,
            fontWeight: 600
        },
        h3: {
            fontSize: '1.25rem',
            color: theme.heading!,
            fontWeight: 600
        },
        h2: {
            fontSize: '1.5rem',
            color: theme.heading!,
            fontWeight: 700
        },
        h1: {
            fontSize: '2.125rem',
            color: theme.heading!,
            fontWeight: 700
        },

        commonAvatar: {
            cursor: 'pointer',
            borderRadius: '8px'
        },
        smallAvatar: {
            width: '24px',
            height: '24px'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem'
        },
        mainContent: {
            backgroundColor: theme.background!,
            width: '100%',
            minHeight: '100vh',
            flexGrow: 1,
            padding: 10,
            paddingTop: '88px',
            marginRight: '20px'
        },
        menuCaption: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.heading!,
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px'
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 500,
            color: theme.darkTextSecondary!,
            textTransform: 'capitalize'
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em'
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1.5em',
            color: theme.darkTextPrimary!
        },
        customInput: {
            marginTop: 1,
            marginBottom: 1,
            '& > label': {
                top: 23,
                left: 0,
                // color: theme.palette.grey[100],
                '&[data-shrink="false"]': {
                    top: 5
                }
            },
            '& > div > input': {
                padding: '30.5px 14px 11.5px !important'
            },
            '& legend': {
                display: 'none'
            },
            '& fieldset': {
                top: 0
            }
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.mode === 'dark' ? theme.heading! : theme.textDark!
        }
    };
}
